<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="rooms"
    name="roomQuickEntry"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="handleRoomCreate"
  >
    <Modal ref="modal" title="Snel aanmaken van ruimtes">
      <FormulateInput
        type="group"
        name="roomtypes"
        :repeatable="true"
        add-label="+ Kamertype"
        remove-label="Kamertype en gekoppelde ruimtes verwijderen"
        validation="required"
        element-class="rooms-group-element"
        group-repeatable-class="tw-border tw-bg-gray-100 tw-border-gray-200 tw-p-4 tw-mb-4 tw-rounded"
      >
        <FormulateInput
          name="type"
          label="Type"
          type='select'
          validation="required"
          :options="options"
        />
        <fieldset class="tw-m-0 tw-px-4 tw-border-gray-cc tw-rounded">
          <legend class="tw--mx-1 tw-my-0 tw-px-1 tw-w-auto tw-font-bold tw-text-sm tw-text-tg-color">
            Voeg ruimtes toe
            <span class="input-error">(minstens één)</span>
          </legend>
          <FormulateInput
            type="group"
            name="surfaces"
            :repeatable="true"
            add-label="+ Ruimte"
            validation="required"
            remove-label="Ruimte verwijderen"
            grouping-class="tw-grid md:tw-grid-cols-4 tw-grid-cols-2 tw-gap-4 tw-my-8"
          >
            <FormulateInput
              name="surface"
              label="Oppervlakte (m²)"
              type="number"
              element-class="tw-w-32 tw-mr-2"
              value=0
              validation="required|min:0"
              min="0"
            />
          </FormulateInput>
        </fieldset>
      </FormulateInput>
      <template #footer>
        <FormulateErrors />
        <div class="tw-flex tw-flex-row tw-space-x-4 tw-justify-end">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            :input-class="['tw-bg-primary tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-check'
              ]"
            />
            Opslaan
          </FormulateInput>
          <FormulateInput
            type="button"
            :input-class="['tw-bg-error tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
            @click="hide"
          >
            <i class="far fa-times tw-mr-2" /> Annuleren
          </FormulateInput>
        </div>
      </template>
    </Modal>
  </FormulateForm>
</template>
<script>
import Modal from '@/components/iam/Modal'
import { addPropertyRoom } from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'

export default {
  name: 'PropertyRoomQuickEntryModal',
  components: { Modal },
  data () {
    return {
      rooms: {},
      options: [
        { value: 0, label: 'Andere' },
        { value: 2, label: 'Badkamer' },
        { value: 14, label: 'Balkon' },
        { value: 9, label: 'Berging' },
        { value: 10, label: 'Bureau' },
        { value: 11, label: 'Dressing' },
        { value: 12, label: 'Eetkamer' },
        { value: 6, label: 'Garage' },
        { value: 16, label: 'Kelder' },
        { value: 3, label: 'Keuken' },
        { value: 1, label: 'Slaapkamer' },
        { value: 15, label: 'Terras' },
        { value: 7, label: 'Toilet' },
        { value: 4, label: 'Tuin' },
        { value: 17, label: 'Veranda' },
        { value: 5, label: 'Woonkamer' },
        { value: 13, label: 'Zwembad' },
        { value: 8, label: 'Zolder' }
      ]
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },

    hide () {
      this.resetForm()
      this.$refs.modal.hide()
    },

    resetForm () {
      this.$formulate.reset('roomQuickEntry')
      this.rooms = {}
    },

    async handleRoomCreate (data) {
      try {
        const propertyId = this.$route.params.id
        const promises = []
        data.roomtypes.forEach(roomType => {
          roomType.surfaces.forEach(surface => {
            const payload = {
              property: propertyId,
              type: roomType.type,
              surface: surface.surface
            }
            promises.push(addPropertyRoom(propertyId, payload))
          })
        })
        const response = await Promise.all(promises)
        successModal('Ruimte(s) succesvol aangemaakt')
        this.hide()
        this.$emit('reload', propertyId)
        return response
      } catch {
        errorModal('Er ging iets mis bij het aanmaken van de ruimte(s).')
      }
    }
  }
}
</script>

<style scoped>
  * >>> .rooms-group-element button {
    @apply tw-bg-success
  }
</style>
