<template>
  <Panel
    title="Indeling"
    :is-collapsible="false"
  >
    <span slot="toolbar-buttons">
      <a
        title="Toevoegen"
        class="btn btn-xs btn-primary"
        @click="showEditModal('room', {})"
      >
        <i class="fa fa-plus" /> Ruimte
      </a>
      <a
        title="Meerdere ruimtes"
        class="btn btn-xs btn-primary"
        @click="showQuickEntryModal"
      >
        <i class="fa fa-plus" /> Meerdere ruimtes aanmaken
      </a>
    </span>
    <div>
      <span v-if="rooms.length === 0">Er zijn geen ruimtes aangemaakt voor dit pand</span>
      <div v-else>
        <div class="margin-bottom-20 text-center">
          <template v-if="bedrooms">
            <span class="roomCount tw-mr-4">{{ bedrooms }}</span>
            <img
              title="Slaapkamer"
              alt="bedrooms"
              height="50px"
              src="@/assets/img/bed.png"
              class="roomIcon"
            >
          </template>
          <template v-if="bathrooms">
            <span class="roomCount tw-mr-4">{{ bathrooms }}</span>
            <img
              title="Badkamer"
              alt="bathrooms"
              height="50px"
              src="@/assets/img/bath.png"
              class="roomIcon"
            >
          </template>
          <template v-if="garage">
            <span
              v-if="garage > 1"
              class="roomCount"
            >
              {{ garage }}
            </span>
            <img
              title="Garage"
              alt="garage"
              height="50px"
              src="@/assets/img/garage.png"
              class="roomIcon"
            >
          </template>
          <template v-if="garden">
            <span
              v-if="garden > 1"
              class="roomCount"
            >
              {{ garden }}
            </span>
            <img
              title="Tuin"
              alt="garden"
              height="50px"
              src="@/assets/img/garden.png"
              class="roomIcon"
            >
          </template>
          <template v-if="kitchen">
            <span
              class="roomCount"
              v-if="kitchen > 1"
            >
              {{ kitchen }}
            </span>
            <img
              title="Keuken"
              alt="kitchen"
              height="50px"
              src="@/assets/img/kitchen.png"
              class="roomIcon"
            >
          </template>
          <template v-if="pool">
            <span
              class="roomCount"
              v-if="pool > 1"
            >
              {{ pool }}
            </span>
            <img
              title="Zwembad"
              alt="kitchen"
              height="50px"
              src="@/assets/img/pool.png"
              class="roomIcon"
            >
          </template>
        </div>
        <div class="row">
          <div
            v-for="room in rooms"
            :key="room.id"
            class="col-sm-4 room"
          >
            <div class="row">
              <div class="col-md-4">
                <img
                  v-if="room.picture"
                  :src="room.picture.url_thumbnail"
                  class="img-responsive"
                >
                <img
                  v-else
                  src="https://iam-media-prod.s3.eu-central-1.amazonaws.com/property_dummy.gif"
                  class="img-responsive"
                >
              </div>
              <div class="col-md-8">
                <h4>{{ room.type_display }} <i class="far fa-globe-europe" /></h4>
                <p style="margin-bottom: 3px;">
                  <strong v-if="roomTitle(room)">{{ roomTitle(room) }}</strong>
                  <span v-else>
                    <em>geen titel</em>
                  </span>
                </p>
                <p style="margin-bottom: 3px;">
                  {{ room.surface }} m<sup>2</sup
                ></p>
                <p
                  style="padding-top: 0px!important; margin-bottom: 3px"
                  class="col-sm-12 no-padding"
                >
                  {{ roomDescription(room)|truncate(50) }}
                </p>
              </div>
            </div>
            <div class="row margin-top-10">
              <div class="col-sm-6">
                <a
                  class="btn btn-xs btn-default btn-block"
                  @click="showEditModal('room', parseRoom(room))"
                >
                  <i class="fa fa-pencil" /> Bewerken
                </a>
              </div>
              <div class="col-sm-6">
                <a
                  @click="deleteRoom(room)"
                  class="btn btn-xs btn-danger btn-block"
                >
                  <i class="fa fa-trash" /> Verwijderen
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EditModal
      ref="editModal"
      :config="editConfig"
      :object="activeObject"
      @save="handleSave"
    />
    <PropertyRoomQuickEntryModal
      ref="quickEntryModal"
      @reload="loadPropertyRooms"
    />
  </Panel>
</template>

<script>
import Panel from '@/components/iam/Panel'
import EditModal from '@/components/iam/EditModal'
import { mapActions, mapGetters } from 'vuex'
import { errorModal, questionModal, startLoadingModal, successModal } from '@/modalMessages'
import PropertyRoomQuickEntryModal from '@/components/properties/PropertyRoomQuickEntryModal'
import { getPropertyRooms, addPropertyRoom, updatePropertyRoom, deletePropertyRoom } from '@/services/properties'

export default {
  name: 'PropertyRooms',
  components: { EditModal, Panel, PropertyRoomQuickEntryModal },
  data () {
    return {
      activeObject: {},
      rooms: []
    }
  },
  computed: {
    ...mapGetters('properties', [
      'getPropertyById',
      'getPropertyPictures'
    ]),
    editConfig () {
      return {
        title: 'Nieuwe kamer',
        segmented: true,
        segments: [
          {
            id: '1',
            fields: [
              {
                value: 'type',
                label: 'Type',
                placeholder: '',
                type: 'select',
                required: true,
                options: {
                  0: 'Andere',
                  2: 'Badkamer',
                  14: 'Balkon',
                  9: 'Berging',
                  10: 'Bureau',
                  11: 'Dressing',
                  12: 'Eetkamer',
                  6: 'Garage',
                  16: 'Kelder',
                  3: 'Keuken',
                  1: 'Slaapkamer',
                  15: 'Terras',
                  7: 'Toilet',
                  4: 'Tuin',
                  17: 'Veranda',
                  5: 'Woonkamer',
                  13: 'Zwembad',
                  8: 'Zolder'
                }
              },
              {
                value: 'surface',
                label: 'Oppervlakte',
                placeholder: '',
                type: 'number',
                validators: ['positive']
              },
              {
                value: 'picture',
                label: 'Foto',
                type: 'picture',
                options: this.pictures
              }
            ]
          },
          {
            id: '2',
            tabbed: true,
            tabs: [
              {
                title: 'nl',
                fields: [
                  {
                    value: 'title_nl',
                    label: 'Naam',
                    placeholder: 'Naam',
                    type: 'text'
                  },
                  {
                    value: 'description_nl',
                    label: 'Omschrijving',
                    placeholder: 'Omschrijving',
                    type: 'textarea'
                  }
                ]
              },
              {
                title: 'fr',
                fields: [
                  {
                    value: 'title_fr',
                    label: 'Naam',
                    placeholder: 'Naam',
                    type: 'text'
                  },
                  {
                    value: 'description_fr',
                    label: 'Omschrijving',
                    placeholder: 'Omschrijving',
                    type: 'textarea'
                  }
                ]
              },
              {
                title: 'en',
                fields: [
                  {
                    value: 'title_en',
                    label: 'Naam',
                    placeholder: 'Naam',
                    type: 'text'
                  },
                  {
                    value: 'description_en',
                    label: 'Omschrijving',
                    placeholder: 'Omschrijving',
                    type: 'textarea'
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    property () {
      return this.getPropertyById(this.$route.params.id)
    },
    pictures () {
      return this.getPropertyPictures(this.$route.params.id)
    },
    bedrooms () {
      return this.rooms.filter(room => room.type === 1).length
    },
    bathrooms () {
      return this.rooms.filter(room => room.type === 2).length
    },
    garage () {
      return this.rooms.filter(room => room.type === 6).length
    },
    garden () {
      return this.rooms.filter(room => room.type === 4).length
    },
    kitchen () {
      return this.rooms.filter(room => room.type === 3).length
    },
    pool () {
      return this.rooms.filter(room => room.type === 13).length
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions('properties', [
      'loadPropertyPictures'
    ]),

    async init () {
      try {
        const propertyId = this.$route.params.id
        await Promise.all([this.loadPropertyRooms(), this.loadPropertyPictures(propertyId)])
      } catch (error) {
        errorModal('Fout bij ophalen van details, probeer het opnieuw.')
      }
    },
    showEditModal: function (name, object) {
      object = JSON.parse(JSON.stringify(object))
      this.activeObject = object
      this.$refs.editModal.show()
    },

    showQuickEntryModal () {
      this.$refs.quickEntryModal.show()
    },

    handleSave (values) {
      if (this.activeObject.id) {
        this.handleRoomEdit(values)
      } else {
        this.handleRoomCreate(values)
      }
    },

    async loadPropertyRooms () {
      try {
        const response = await getPropertyRooms(this.property.id)
        this.rooms = response.data?.results
        return response
      } catch {
        errorModal('Fout bij het ophalen van de kamers.')
      }
    },

    async handleRoomEdit (values) {
      try {
        const payload = { ...values }
        payload.property = this.property.id
        const response = await updatePropertyRoom(this.property.id, this.activeObject.id, payload)
        successModal('De ruimte is gewijzigd')
        await this.loadPropertyRooms()
        this.$refs.editModal.clear()
        this.$refs.editModal.hide()
        return response
      } catch {
        errorModal('Er ging iets mis bij het wijzigen van de ruimte. Gelieve nogmaals te proberen.')
      }
    },

    async handleRoomCreate (values) {
      try {
        const payload = { ...values }
        payload.property = this.property.id
        startLoadingModal('De ruimte wordt aangemaakt...')
        const response = await addPropertyRoom(this.property.id, payload)
        successModal('De ruimte is aangemaakt')
        await this.loadPropertyRooms()
        this.$refs.editModal.clear()
        this.$refs.editModal.hide()
        return response
      } catch {
        errorModal('Er ging iets mis bij het aanmaken van de ruimte. Gelieve nogmaals te proberen.')
      }
    },

    async deleteRoom (room) {
      try {
        const result = await questionModal('Weet je zeker dat je deze ruimte wil verwijderen?')
        if (result.value) {
          const response = await deletePropertyRoom(this.property.id, room.id)
          successModal('Ruimte is verwijderd')
          await this.loadPropertyRooms()
          return response
        }
      } catch {
        errorModal('Er ging iets mis bij het verwijderen van de ruimte.')
      }
    },
    roomTitle (room) {
      const title = room?.descriptions?.filter((desc) => desc.language === 'nl')
      if (title?.length) {
        return title[0].title
      }
      return ''
    },
    roomDescription (room) {
      const description = room?.descriptions?.filter((desc) => desc.language === 'nl')
      if (description?.length) {
        return description[0].description
      }
      return '-'
    },
    parseRoom (room) {
      const nlDesc = room?.descriptions?.filter((desc) => desc.language === 'nl')[0]
      const frDesc = room?.descriptions?.filter((desc) => desc.language === 'fr')[0]
      const enDesc = room?.descriptions?.filter((desc) => desc.language === 'en')[0]
      return {
        id: room?.id,
        is_room_edit: true,
        type: room?.type,
        surface: room?.surface,
        picture: room?.picture ? room.picture.id : null,
        title_nl: nlDesc?.title,
        title_fr: frDesc?.title,
        title_en: enDesc?.title,
        description_nl: nlDesc?.description,
        description_fr: frDesc?.description,
        description_en: enDesc?.description
      }
    }
  }
}
</script>

<style scoped>
  .room {
    position: relative;
    padding-bottom: 3px;
  }

  .roomCount {
    font-size: 40px;
    font-weight: 700;
    vertical-align: middle;
    color: black;
  }

  .roomIcon {
    margin-right: 30px;
    display: inline;
    height: 50px;
  }
</style>
